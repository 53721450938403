import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { localStorageUtil } from './utils';
import { ETheme, themes } from './themes/theme';
import { BootResponse } from './constants/apiResponses.types';
import { ELocalStorageKeys, ESessionStorageKeys } from './constants/enums';
import useApi from './hooks/useApi';
import useAnalytics from './hooks/useAnalytics';
import useSetCssVars from './hooks/useSetCssVars';
import { LoginPage } from './pages/login/LoginPage';
import Shop from './pages/shop/shop';
import ShopV2 from './pages/shop/shopV2';
import Maintenance from './pages/maintenance/maintenance';
import Checkout from './pages/checkout/checkout';
import Failed from './pages/failed/failed';
import Support from './pages/Support/Support';
import DevDialog from './components/DevDialog/DevDialog';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { AppchargeCheckoutInit } from 'appcharge-checkout-reactjs-sdk';
import { initI18n } from './i18n';
import './styles.scss';

const theme = createTheme(themes[ETheme.LIGHT]);

const DEFAULT_TTL = 3600000;
const SHARED_UI_VER = process.env.REACT_APP_SHARED_UI_VERSION;
const SDK_VER = process.env.REACT_APP_CHECKOUT_SDK_VERSION;
const ENV = process.env.REACT_APP_ENV as any;

console.log('SUIV: ' + SHARED_UI_VER);
console.log('ICSV: ' + SDK_VER);

function App() {
  const API = useApi({});
  const { t } = useTranslation();
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const isUsingApiV2 = publisherMetaData?.featureFlags?.store_daily_bonus_badge;
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [themeLoaded, setThemeLoaded] = useState(false);
  const [showDevDialog, setShowDevDialog] = useState(false);

  const supportMultiLanguageEnabled = publisherMetaData?.featureFlags.store_multi_language_support;

  if (API.getPublisherMeta.isIdle) {
    API.getPublisherMeta.refetch();
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source') === 'webview' ? 'webview' : '';
    sessionStorage.setItem('source', source);
  }, []);

  useEffect(() => {
    const sessionData = localStorageUtil.get(ESessionStorageKeys.SESSION_DATA);
    const params = new URLSearchParams(window.location.search);
    const sessionIdParam = params.get('sessionId');

    if (!sessionData || Date.now() > sessionData.ttl) {
      const sessionId = sessionIdParam ? sessionIdParam : uuidv4();
      const newTTl = Date.now() + DEFAULT_TTL;
      localStorageUtil.set(ESessionStorageKeys.SESSION_DATA, {
        id: sessionId,
        ttl: newTTl,
      });
    }
    if (sessionIdParam) {
      params.delete('sessionId');
      navigate(`${window.location.pathname}?${params.toString()}`, {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (publisherMetaData && supportMultiLanguageEnabled) {
      const updateI18nConfig = async () => {
        await initI18n(API.getLanguagesList, publisherMetaData.storeTheme.publisherId);
      };
      updateI18nConfig();
    }
  }, [publisherMetaData, supportMultiLanguageEnabled]);

  // Set global CSS vars for theme
  useSetCssVars();

  const setFavicon = () => {
    if (publisherMetaData) {
      const fav = document.getElementById('favicon');
      fav!.setAttribute('href', publisherMetaData.storeTheme.general.favicon);
    }
  };

  const setManifest = () => {
    if (publisherMetaData) {
      const head = document.head || document.getElementsByTagName('head')[0];

      const link = document.createElement('link');
      link.rel = 'manifest';

      const myDynamicManifest = {
        name: publisherMetaData.publisher?.storeTabName,
        short_name: publisherMetaData.publisher?.storeTabName,
        start_url: '.',
        background_color: '#ffffff',
        theme_color: '#000000',
        icons: [
          {
            src: publisherMetaData.storeTheme.general.logo,
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: publisherMetaData.storeTheme.general.logo,
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      };

      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], {
        type: 'application/json',
      });
      const manifestURL = URL.createObjectURL(blob);

      link.href = manifestURL;
      head.appendChild(link);

      const linkAppleTouchIcon = document.createElement('link');
      link.setAttribute('rel', 'apple-touch-icon');
      link.setAttribute('href', publisherMetaData.storeTheme.general.logo);
      document.head.appendChild(linkAppleTouchIcon);
    }
  };

  const logout = async () => {
    API.logout.mutate(
      { data: {} },
      {
        onSettled: () => {
          // Clear requests
          API.getPostLoginData.remove();
          localStorageUtil.clear();
          localStorageUtil.set(ESessionStorageKeys.SESSION_DATA, {
            id: uuidv4(),
            ttl: Date.now() + DEFAULT_TTL,
          });
          localStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
          navigate('./login');
        },
      }
    );
  };

  if (API.getPublisherMeta.isSuccess && publisherMetaData) {
    if (!themeLoaded) {
      window.document.title = publisherMetaData.publisher?.storeTabName || t('gamingStore');
      analytics.init();
      setFavicon();
      setManifest();
      setThemeLoaded(true);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          background: 'transparent',
          width: 30,
          height: 30,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 100000,
        }}
        onClick={(e) => {
          if (
            (window.location.hostname.includes('.appcharge.com') ||
              window.location.hostname.includes('.appchargestore.com') ||
              window.location.hostname.includes('localhost')) &&
            e.detail === 3
          ) {
            setShowDevDialog(true);
          }
        }}
      />
      {showDevDialog && <DevDialog onClose={() => setShowDevDialog(false)} />}
      {window.location.hostname.includes('.appcharge.com') &&
        (localStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE) ||
          localStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE) ||
          localStorageUtil.get(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE)) && (
          <div
            style={{
              position: 'fixed',
              zIndex: 99,
              background: 'red',
              width: '100vw',
              padding: '0.5em',
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {t('someRoutesMocked')}
          </div>
        )}
      {themeLoaded && (
        <Routes>
          <Route path="/login/*" element={<LoginPage initAnalytics={analytics.init} />} />
          {isUsingApiV2 ? (
            <Route path="/shop" element={<ShopV2 logout={logout} />} />
          ) : (
            <Route path="/shop" element={<Shop logout={logout} />} />
          )}
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/checkout/:orderId" element={<Checkout />} />
          <Route path="/failed" element={<Failed />} />
          <Route path="/support/:supportType/:storePhase/:orderId?" element={<Support />} />
          <Route path="*" element={<Navigate to="/shop" replace />} />
        </Routes>
      )}
      <AppchargeCheckoutInit environment={ENV === 'production' ? 'prod' : ENV} />
    </ThemeProvider>
  );
}

export default App;
