import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${(props: any) => (props.showOverlay ? 'rgba(0, 0, 0, 0.75)' : '')};
  position: fixed;
  z-index: 2000;
  color: white;
  gap: 1em;
` as any;

const StyledProcessingText = styled.span`
  color: white;
  font-size: 1.5em;
  font-family: Arial, Helvetica, sans-serif;
`;

type SpinnerProps = {
  hideProcessingText?: boolean;
  showOverlay?: boolean;
};

const Spinner = ({ hideProcessingText = false, showOverlay = true }: SpinnerProps) => {
  const { t } = useTranslation();

  return (
    <StyledContainer showOverlay={showOverlay}>
      <CircularProgress
        sx={{
          color: 'white',
        }}
      />
      {!hideProcessingText && (
        <StyledProcessingText>{t('spinner.processing')}</StyledProcessingText>
      )}
    </StyledContainer>
  );
};

export default Spinner;
