import { BootResponse, OfferData } from '../../constants/apiResponses.types';
import useApi from '../../hooks/useApi';
import { ActionButton, DailyBonus, PostPurchase } from '@appcharge/shared-ui';
import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import animationData from './confetti-animation.json';
import { EOrderStatus } from '../../pages/checkout/checkout.types';
import useCustomEvents from '../../hooks/useCustomEvents';
import {
  ECollectType,
  EEventsType,
  EPopupSubType,
  EResultOptions,
  EStorePhase,
} from '../../constants/enums';
import './style.scss';
import { createGradientOrSolid, getPlatformData } from '../../utils';
import { Gradient } from '@appcharge/shared-ui/lib/components/Helper/types';
import { useTranslation } from 'react-i18next';

export const PopUpsHandlerV2 = ({
  setProcessing,
  onPurchaseClick,
  currentPopupDisplayed,
  assignNextPopUp,
  setClickedClosePopups,
  nextAvaliableDate,
  dailyBonusRef,
  postPurchaseRef,
  indexToCollect,
}: any) => {
  const [orderProcessing, setOrderProcessing] = useState<string>();
  const [orderState, setOrderState] = useState<string>();
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [nextProducts, setNextProducts] = useState();

  const API = useApi({ orderId: orderProcessing });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const { t } = useTranslation();

  // refetch order status when orderProcessing changes
  useEffect(() => {
    if (orderProcessing) {
      API.getOrder.refetch().catch((err) => console.error(err));
    }
  }, [orderProcessing]);

  // check if order status changed
  useEffect(() => {
    if (orderProcessing && API.getOrder.data) {
      setOrderState(API.getOrder.data?.state);
    }
  }, [API.getOrder?.data?.state !== orderState]);

  useEffect(() => {
    if (orderProcessing === undefined) return;
    if (
      orderState === EOrderStatus.CHARGE_SUCCEED ||
      orderState === EOrderStatus.CHARGE_FAILED ||
      orderState === EOrderStatus.PAYMENT_FAILED
    ) {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_id: currentPopupDisplayed?.offerId,
          offer_name: currentPopupDisplayed?.offerName,
          result:
            orderState === EOrderStatus.CHARGE_SUCCEED
              ? EResultOptions.SUCCESS
              : EResultOptions.FAILED,
          platform: getPlatformData(),
          type: ECollectType.POPUP,
          reason: orderState !== EOrderStatus.CHARGE_SUCCEED ? orderState : '',
        },
        EStorePhase.POST_LOGIN
      );
    }
    if (orderState === EOrderStatus.CHARGE_SUCCEED) {
      setProcessing(false);
      setShowConfetti(true);
      if (currentPopupDisplayed.subType === EPopupSubType.DAILY_BONUS)
        dailyBonusRef?.current?.onOrderSuccess();
      else postPurchaseRef?.current?.onOrderSuccess();
    } else if (
      orderState === EOrderStatus.CHARGE_FAILED ||
      orderState === EOrderStatus.PAYMENT_FAILED
    ) {
      setProcessing(false);
      setShowFailPopup(true);
    } else {
      const timer = setTimeout(() => {
        API.getOrder.refetch().catch((err) => console.error(err));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [orderState, API.getOrder.isRefetching]);

  useEffect(() => {
    if (currentPopupDisplayed) {
      const { productsSequence, startOver, isBadgeNextProduct } = currentPopupDisplayed;
      const isSingleProduct = productsSequence?.length === 1;
      const nextProductExists = productsSequence[indexToCollect + 1]?.products?.length > 0;

      // If there is only one product, show that product
      if (startOver && isSingleProduct) {
        setNextProducts(productsSequence[0]?.products);
        return;
      }

      // If next product is linked to a badge click, show next badge product
      if (isBadgeNextProduct) {
        setNextProducts(productsSequence[indexToCollect]?.products);
      } else {
        if (nextProductExists) {
          setNextProducts(productsSequence[indexToCollect + 1]?.products);
        } else if (startOver) {
          setNextProducts(productsSequence[0]?.products); // Restart from beginning
        }
      }
    }
  }, [currentPopupDisplayed]);

  const onCollect = async (item: OfferData): Promise<void> => {
    setProcessing(undefined);
    setOrderState(undefined);
    API.getOffersV2.refetch();
    const freeOrderData: any = {
      data: {
        clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
        paymentProvider: 'free',
        paymentMethod: 'free',
        offerId: item.offerId,
      },
    };
    customEvents.sendCustomEvent(
      EEventsType.COLLECT_CLICKED,
      {
        offer_Id: item.offerId,
        offer_name: item.offerName,
        status: orderState,
        platform: getPlatformData(),
        type: ECollectType.POPUP,
        sub_type: item.subType,
      },
      EStorePhase.POST_LOGIN
    );
    customEvents.sendCustomEvent(EEventsType.POP_UP_CLOSED, {
      offer_id: item.offerId,
      sub_type: item.subType,
      reason: 'collect',
    });
    try {
      setProcessing(true);
      API.createOrderV2.mutate(freeOrderData, {
        onSuccess: (orderData) => {
          setOrderProcessing(orderData.data.orderId);
        },
        onError: (error) => {
          console.error(error);
          setProcessing(false);
          setShowFailPopup(true);
        },
      });
    } catch (error) {
      console.error('creating an order failed', error);
    }
  };

  const onClose = (
    isShowingNextProduct: boolean,
    offerId: string,
    currentProdSeqIndex = 1,
    subType: string
  ) => {
    setOrderProcessing(undefined);
    setProcessing(undefined);
    setOrderState(undefined);
    API.getOffersV2.refetch();
    setClickedClosePopups((prevState: any) => [...prevState, offerId]);
    setTimeout(assignNextPopUp, 100);
    if (!isShowingNextProduct) {
      API.sendOrderImpression({
        offerId,
        productSequenceIndex: currentProdSeqIndex,
      });
      customEvents.sendCustomEvent(EEventsType.POP_UP_CLOSED, {
        offerId,
        sub_type: subType,
      });
    }
  };

  const onTryAgain = () => {
    setShowFailPopup(false);
    window.location.reload();
  };

  const onAnimationSuccessEndNoPreview = () => {
    if (currentPopupDisplayed) {
      setClickedClosePopups((prevState: any) => [...prevState, currentPopupDisplayed?.offerId]);
    }
    assignNextPopUp();
  };

  return (
    <>
      {currentPopupDisplayed && currentPopupDisplayed.subType === EPopupSubType.DAILY_BONUS && (
        <DailyBonus
          key={currentPopupDisplayed.offerId}
          ref={dailyBonusRef}
          title={currentPopupDisplayed?.offerUi?.specialOffer?.title || ''}
          titleSize={currentPopupDisplayed?.offerUi?.specialOffer?.fontSize + 'px'}
          titleWeight={currentPopupDisplayed?.offerUi?.specialOffer?.fontWeight}
          titleColor={
            currentPopupDisplayed?.offerUi?.specialOffer?.fontColor
              ? createGradientOrSolid(
                  currentPopupDisplayed.offerUi.specialOffer?.fontColor as Gradient
                )
              : 'white'
          }
          borderWidth={currentPopupDisplayed?.offerUi?.borderWidth}
          borderColor={currentPopupDisplayed?.offerUi?.borderColor}
          products={currentPopupDisplayed?.productsSequence[indexToCollect]?.products}
          onCollect={() => onCollect(currentPopupDisplayed)}
          onClose={({ isShowingNextProduct }) =>
            onClose(
              isShowingNextProduct,
              currentPopupDisplayed.offerId,
              currentPopupDisplayed?.productsSequence[indexToCollect].index,
              currentPopupDisplayed.subType || ''
            )
          }
          backgroundImage={currentPopupDisplayed?.offerUi?.backgroundImage}
          backgroundColor={currentPopupDisplayed?.offerUi?.specialOffer?.backgroundColor?.colorOne}
          buttonText={t('collect')}
          nextProducts={nextProducts}
          nextAvailableCollectUTC={nextAvaliableDate}
          showEvery={currentPopupDisplayed?.showEvery}
          onAnimationSuccessEndNoPreview={onAnimationSuccessEndNoPreview}
          showCloseButton={publisherMetaData.featureFlags?.store_present_daily_bonus_x_icon}
          isBadgeNextProduct={currentPopupDisplayed?.isBadgeNextProduct}
        />
      )}
      {currentPopupDisplayed && currentPopupDisplayed.subType === EPopupSubType.POST_PURCHASE && (
        <PostPurchase
          ref={postPurchaseRef}
          key={currentPopupDisplayed.offerId}
          title={currentPopupDisplayed.offerUi.specialOffer?.title || ''}
          titleColor={
            currentPopupDisplayed.offerUi.specialOffer?.fontColor
              ? createGradientOrSolid(
                  currentPopupDisplayed.offerUi.specialOffer?.fontColor as Gradient
                )
              : 'white'
          }
          titleWeight={currentPopupDisplayed.offerUi.specialOffer?.fontWeight}
          titleSize={currentPopupDisplayed.offerUi.specialOffer?.fontSize + 'px'}
          backgroundColor={currentPopupDisplayed.offerUi?.specialOffer?.backgroundColor?.colorOne}
          backgroundImage={currentPopupDisplayed.offerUi.backgroundImage}
          borderColor={currentPopupDisplayed?.offerUi?.borderColor}
          borderWidth={currentPopupDisplayed.offerUi.borderWidth}
          buttonText={t('collect')}
          products={currentPopupDisplayed.productsSequence[indexToCollect].products}
          onActionBtnClicked={() =>
            currentPopupDisplayed.productsSequence[indexToCollect].price.price === 0
              ? onCollect(currentPopupDisplayed)
              : onPurchaseClick(currentPopupDisplayed)
          }
          showCloseButton={true}
          onClose={({ isShowingNextProduct }: any) =>
            onClose(
              isShowingNextProduct,
              currentPopupDisplayed.offerId,
              currentPopupDisplayed.productsSequence[indexToCollect].index,
              currentPopupDisplayed.subType || ''
            )
          }
          price={currentPopupDisplayed.productsSequence[indexToCollect].price}
        />
      )}
      {showConfetti && (
        <Lottie
          animationData={animationData}
          loop={false}
          style={{
            position: 'absolute',
            zIndex: 100000,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          onComplete={() => {
            setShowConfetti(false);
          }}
        ></Lottie>
      )}
      {showFailPopup && (
        <div className={'popup-handler__fail-screen'}>
          <h2>{t('failed.somethingWentWrong')}</h2>
          <ActionButton
            className={'popup-handler__fail-screen__action-button'}
            borderRadius={'7px'}
            text={'Try Again'}
            action={onTryAgain}
          ></ActionButton>
        </div>
      )}
    </>
  );
};
