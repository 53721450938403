import useApi from '../../hooks/useApi';
import './style.scss';
import { BootResponse, LoginResponse } from '../../constants/apiResponses.types';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import {
  AcCircularLoader,
  Background,
  Login as LoginDeprecated,
  Overlay,
  useIsDesktop,
  useIsLandscape,
} from '@appcharge/shared-ui';
import CookieConsent from '../../components/cookie-consent/cookie-consent';
import useUrlQuery from '../../hooks/useUrlQuery';
import useCustomEvents from '../../hooks/useCustomEvents';
import { getPlatformData, localStorageUtil } from '../../utils';
import {
  EBundlesInternalViewModel,
  EEventsType,
  ELocalStorageKeys,
  ELoginResultReason,
  EPlatformType,
  EQueryParams,
  EResultOptions,
  ESessionStorageKeys,
  EStorePhase,
  ESupportType,
  ELoginMethods,
  ELoginMode,
} from '../../constants/enums';
import { LoginData } from '@appcharge/shared-ui/lib/components/LoginPage/types';
import InfoModal from '../../components/InfoModal/InfoModal';
import useFacebook from '../../hooks/useFacebook';
import { Stack } from '@mui/material';
import useGoogle from '../../hooks/useGoogle';
import { browserName, isAndroid, isIOS, isMobile, osName } from 'react-device-detect';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import { LoginProps, LoginRequestData } from './types';
import { loginPageService } from './login-page.service';
import { Login } from './Components/Login';
import useLoginEvents from '../../hooks/useLoginEvents';

const ENV = process.env.REACT_APP_ENV as any;

export const LoginPage: React.FC<LoginProps> = ({ initAnalytics }) => {
  const API = useApi({
    platform: isMobile ? osName : browserName,
  });
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const { sendPublisherWebhookLoginEvent } = useLoginEvents();
  const utmSource = useUrlQuery(EQueryParams.UTM_SOURCE);
  const localAddress = useUrlQuery(EQueryParams.LOCAL_ADDRESS);
  const [showCookieOverlay, setShowCookieOverlay] = useState(
    ENV !== 'dev' && localStorageUtil.get(ELocalStorageKeys.ANALYTICS) === null
  );
  const [isProductMadness, setIsProductMadness] = useState(false);
  const [otpProductMadness, setIsOtpProductMadness] = useState(false);
  const [loginBgColor, setLoginBgColor] = useState('');
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [proofKey, setProofKey] = useState('');
  const [otpToken, setOtpToken] = useState('');
  const [loggingWithProofKey, setLoggingWithProofKey] = useState(false);
  const location = useLocation();
  const isDesktop = useIsDesktop();
  const isLandscape = useIsLandscape();
  const { t } = useTranslation();

  const isUsingLoginV2 = ['dev', 'staging', 'sandbox'].includes(ENV);
  const LoginVersioned = isUsingLoginV2 ? Login : LoginDeprecated;

  const facebook = useFacebook(publisherMetaData.integration.playersAuthentication.fbAppId);

  const urlParams = new URLSearchParams(window.location.search);

  const google = useGoogle();
  const redirectState = localStorageUtil.get(ESessionStorageKeys.REDIRECT_STATE);
  const supportMultiLanguageEnabled = publisherMetaData.featureFlags.store_multi_language_support;

  useEffect(() => {
    const onLoginLand = async () => {
      if (localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN)) {
        navigate(`../shop${utmSource ? `?utm_source=${utmSource}` : ''}`);
      } else {
        if (localStorageUtil.get(ELocalStorageKeys.PUBLISHER_META) === null) {
          await API.getPublisherMeta.refetch();
        }
      }
    };
    onLoginLand();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const eventType: EEventsType = loginPageService.isWebviewBrowser()
      ? EEventsType.WEBVIEW_LAND
      : EEventsType.LOGIN_LAND;
    customEvents.sendCustomEvent(
      eventType,
      loginPageService.getLoginEventsData({
        eventName: EEventsType.LOGIN_LAND,
        gameName: publisherMetaData.publisher?.storeTabName,
      }),
      EStorePhase.PRE_LOGIN
    );
  }, [location]);

  useEffect(() => {
    initAnalytics(publisherMetaData.integration.googleAnalyticsMeasurementId);
  }, [showCookieOverlay, initAnalytics, publisherMetaData]);

  // prevent back-forward cache
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    return () => {
      window.onpageshow = null;
    };
  }, []);

  useEffect(() => {
    if (redirectState) {
      const loginMethod = redirectState.split('redirect')[0];
      sendPublisherWebhookLoginEvent(EEventsType.LOGIN_CANCELED, loginMethod);
    }
  }, []);

  // Add boot vars to CSS VARS
  useEffect(() => {
    const generalTheme = publisherMetaData.storeTheme.general;
    const buttonTextColor = generalTheme.buttonTextColor;
    const root = document.documentElement;
    root.style.setProperty('--button-text-color', buttonTextColor);
  }, [publisherMetaData.storeTheme.general, publisherMetaData.storeTheme.storeScreen]);

  useEffect(() => {
    const checkURLforProofKey = () => {
      const proofKeyParam = urlParams.get('proofKey');
      const tokenKeyParam = urlParams.get('token');

      if (proofKeyParam && publisherMetaData) {
        setProofKey(proofKeyParam);
        // setMode(ELoginMode.OTP);
      }
      if (tokenKeyParam && publisherMetaData) {
        setOtpToken(tokenKeyParam);
      }
    };

    setIsProductMadness(
      [EBundlesInternalViewModel.JACKPOT_SLOTS, EBundlesInternalViewModel.BIG_FISH_CASINO].includes(
        publisherMetaData.storeTheme.general.bundlesInternalViewModel
      )
    );

    checkURLforProofKey();
  }, [publisherMetaData]);

  useEffect(() => {
    if (isProductMadness && location.pathname.includes('otp')) {
      setIsOtpProductMadness(true);
    } else {
      setIsOtpProductMadness(false);
    }

    if (isProductMadness) {
      setLoginBgColor(
        publisherMetaData.storeTheme.general.bundlesInternalViewModel ===
          EBundlesInternalViewModel.JACKPOT_SLOTS
          ? '#220E3C'
          : '#060D3B'
      );
    }
  }, [isProductMadness, location.pathname]);

  const failedLoginHandler = async (method: string, msg?: string) => {
    customEvents.sendCustomEvent(
      EEventsType.LOGIN_RESULT,
      {
        authMethod: method,
        result: EResultOptions.FAILED,
        reason: msg ?? ELoginResultReason.UNKNOWN,
        platform: getPlatformData(),
      },
      EStorePhase.PRE_LOGIN
    );
    const loginEventOptions: Record<string, string> = {
      result: EResultOptions.FAILED,
      reason: msg ?? ELoginResultReason.UNKNOWN,
    };
    await sendPublisherWebhookLoginEvent(
      EEventsType.LOGIN_RESULT,
      method as any,
      loginEventOptions
    );
    navigate(`../failed?error=auth${msg ? `&msg=${msg}` : ''}`);
  };

  const loginResponse = async (response: LoginData) => {
    startLoader();
    if (response.authMethod === ('facebook' as any)) {
      try {
        response.profileImageUrl = await facebook.getFacebookProfile(response.userToken!);
      } catch (error) {
        console.error('Fail to load profile picture', error);
        // Handle the error or take appropriate action here
      }
    }
    if (response.authMethod === ('google' as any)) {
      response.profileImageUrl = await google.getGoogleProfile(response.userToken!);
    }
    const sessionData = localStorageUtil.get(ESessionStorageKeys.SESSION_DATA);
    const loginData: {
      data: LoginRequestData;
    } = {
      data: {
        publisherId: publisherMetaData.storeTheme.publisherId,
        localAddress: localAddress,
        sessionId: sessionData.id,
        ...response,
      },
    };
    if (publisherMetaData?.featureFlags.store_send_os_authlogin) {
      loginData.data.os = isIOS
        ? EPlatformType.IOS
        : isAndroid
          ? EPlatformType.ANDROID
          : EPlatformType.WEB;
    }
    if (response.authMethod === ('otp' as any) && proofKey) {
      setLoggingWithProofKey(true);
    }
    API.login.mutate(loginData, {
      onSuccess: async (data: { data: LoginResponse }) => {
        if (data.data.errorCode === 100) {
          return failedLoginHandler(response.authMethod, data.data.errorMessage);
        }
        data.data.sessionToken &&
          localStorageUtil.set(ELocalStorageKeys.SESSION_TOKEN, data.data.sessionToken);
        localStorageUtil.set(ELocalStorageKeys.PLAYER_DATA, {
          playerId: data.data.playerId,
          playerName: data.data.playerName,
          playerCountry: data.data.playerCountry,
          profileImageUrl: data.data.profileImageUrl,
        });
        customEvents.setDistinctId();
        customEvents.sendCustomEvent(
          EEventsType.LOGIN_RESULT,
          {
            authMethod: response.authMethod,
            result: EResultOptions.SUCCESS,
            reason: ELoginResultReason.OK,
            platform: getPlatformData(),
          },
          EStorePhase.POST_LOGIN
        );
        const loginEventOptions: Record<string, string> = {
          result: EResultOptions.SUCCESS,
          playerID: data.data.playerId,
        };
        await sendPublisherWebhookLoginEvent(
          EEventsType.LOGIN_RESULT,
          response.authMethod,
          loginEventOptions
        );
        setProcessing(false);
        navigate(`../shop${utmSource ? `?utm_source=${utmSource}` : ''}&login_redirect=true`);
      },
      onError: (error: any) => {
        setProcessing(false);
        if (error.data?.errorCode === 100) {
          failedLoginHandler(response.authMethod, error.data.errorMessage);
        } else {
          failedLoginHandler(response.authMethod, error.response?.data?.message || null);
        }
      },
    });
  };

  const stopLoader = () => {
    setProcessing(false);
  };

  const startLoader = () => {
    setProcessing(true);
  };

  const setMode = (mode: ELoginMode) => {
    return mode !== 'providers' ? navigate(`./${mode}`) : navigate(-1);
  };

  const openInfoModal = (url: string) => {
    setIframeUrl(url);
    setShowInfoModal(true);
  };

  const loginButtonClickWrapper = async (
    callback: Function,
    method: ELoginMethods,
    event = EEventsType.LOGIN_CLICKED as string
  ) => {
    if (method !== ('apple' as ELoginMethods.APPLE)) startLoader();
    customEvents.sendCustomEvent(
      event,
      loginPageService.getLoginEventsData({
        eventName: event,
        method,
        gameName: publisherMetaData.publisher?.storeTabName,
      }),
      EStorePhase.PRE_LOGIN
    );
    if (event === EEventsType.LOGIN_CLICKED) {
      localStorageUtil.set(ESessionStorageKeys.REDIRECT_STATE, `${method}redirect`);
      await sendPublisherWebhookLoginEvent(EEventsType.LOGIN_CLICKED, method);
    }
    callback();
  };

  const closeInfoModal = () => {
    setShowInfoModal(false);
  };

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    if (isStandalone) {
      sessionStorage.setItem('source', 'home screen bookmark');
    }
  }, []);

  const trackLoginEvent = async (eventName: string, method: any) => {
    await sendPublisherWebhookLoginEvent(eventName as EEventsType, method);
    if (eventName === EEventsType.LOGIN_CANCELED && redirectState) {
      customEvents.sendCustomEvent(
        EEventsType.LOGIN_CANCELED,
        loginPageService.getLoginEventsData({
          eventName: EEventsType.LOGIN_CANCELED,
          gameName: publisherMetaData.publisher?.storeTabName,
        }),
        EStorePhase.PRE_LOGIN
      );
      localStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
    }
  };

  if (loggingWithProofKey) {
    return (
      <Overlay overlayPercentage={80} zIndex={10000}>
        <AcCircularLoader text={t('processing')} />
      </Overlay>
    );
  }

  // Remove after login refactor- moved to a hook
  const handleWebviewEvents = (eventData: any) => {
    const options = eventData.data
      ? Object.assign(
          eventData.data,
          loginPageService.getLoginEventsData({
            eventName: EEventsType.WEBVIEW_LAND,
            gameName: publisherMetaData.publisher?.storeTabName,
          })
        )
      : loginPageService.getLoginEventsData({
          eventName: EEventsType.WEBVIEW_LAND,
          gameName: publisherMetaData.publisher?.storeTabName,
        });
    customEvents.sendCustomEvent(eventData.event, options, EStorePhase.PRE_LOGIN);
  };

  const loginProps = {
    isMobilePreview: false,
    playerAuthData: publisherMetaData.integration.playersAuthentication,
    stopLoader: stopLoader,
    startLoader: startLoader,
    loginLogoSize: publisherMetaData.storeTheme.login.loginLogoSize,
    otpButton: publisherMetaData.storeTheme.login.otpButton,
    getOtp: API.getOTP,
    text: publisherMetaData.storeTheme.login.text,
    textColor: publisherMetaData.storeTheme.login.textColor,
    textSize: publisherMetaData.storeTheme.login.textSize,
    textWeight: publisherMetaData.storeTheme.login.textWeight,
    logo:
      publisherMetaData.storeTheme.login.loginLogoImage ||
      publisherMetaData.storeTheme.general.logo,
    loginResponse: loginResponse,
    loginButtonClickWrapper: loginButtonClickWrapper,
    fontFamily:
      publisherMetaData.storeTheme.login.font || publisherMetaData.storeTheme.general.font,
    termsAndConditionsLink: publisherMetaData.publisher.termsAndConditionsUrl,
    privacyPolicyLink: publisherMetaData.publisher.privacyPolicyUrl,
    openInfoModal: openInfoModal,
    onContactSupport: () => {
      customEvents.sendCustomEvent(
        EEventsType.SUPPORT_FORM_OPEN,
        {
          phase: EStorePhase.PRE_LOGIN,
          supportModel: publisherMetaData.supportConfiguration?.supportModel,
        },
        EStorePhase.PRE_LOGIN
      );
      if (publisherMetaData.supportConfiguration?.externalSupportUrl) {
        API.sendEmptySupport(
          publisherMetaData.storeTheme.publisherId,
          ESupportType.PRE_LOGIN,
          EStorePhase.PRE_LOGIN
        );
        window.location.href = publisherMetaData.supportConfiguration.externalSupportUrl;
      } else {
        navigate(`/support/${ESupportType.PRE_LOGIN}/${EStorePhase.PRE_LOGIN}`);
      }
    },
    otpProofKey: proofKey,
    otpTokenKey: otpToken,
    flex: 1,
    trackLoginEvent: trackLoginEvent,
    setMode: setMode,
    gameName: publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel,
    sendEventCallback: handleWebviewEvents,
    openLinksInNewTab: publisherMetaData?.storeTheme?.login?.openLinksInNewTab,
    translations: {
      signInWithFacebook: t('login.signInWithFacebook'),
      signInWithGoogle: t('login.signInWithGoogle'),
      signInWithApple: t('login.signInWithApple'),
      loginWithPlayerId: t('login.loginWithPlayerId'),
      loginWithUsername: t('login.loginWithUserName'),
      userName: t('login.userName'),
      password: t('login.password'),
      login: t('login.login'),
      contactSupport: t('login.contactSupport'),
      privacyPolicy: t('login.privacyPolicy'),
      termsAndConditions: t('login.termsAndConditions'),
      byLoggingIn: t('login.byLoggingIn'),
      and: t('login.and'),
      back: t('login.back'),
      playerId: t('login.playerId'),
      leavingWebviewTitle: t('login.leavingWebviewTitle'),
      leavingWebviewDescription: t('login.leavingWebviewDescription'),
      leavingWebviewButton: t('login.leavingWebviewButton'),
      or: t('login.or'),
    },
  };

  return (
    <div>
      {(processing || proofKey) && (
        <Overlay overlayPercentage={80} zIndex={10000}>
          <AcCircularLoader text={t('processing')} />
        </Overlay>
      )}
      <Background
        height={isProductMadness && (isDesktop || isLandscape) ? '100vh' : '100%'}
        width={isProductMadness && (isDesktop || isLandscape) ? '100%' : '100vw'}
        backgroundImageMobile={
          publisherMetaData.storeTheme.login.loginBackgroundImageMobile ||
          publisherMetaData.storeTheme.general.backgroundImageMobile
        }
        backgroundImageDesktop={
          (otpProductMadness
            ? publisherMetaData.storeTheme.general.backgroundImageDesktop
            : publisherMetaData.storeTheme.login.loginBackgroundImageDesktop) ||
          publisherMetaData.storeTheme.general.backgroundImageDesktop
        }
        backgroundPosition={isProductMadness && isDesktop ? 'center bottom' : 'center top'}
        backgroundSize={
          isProductMadness && !otpProductMadness && !isDesktop && !isLandscape ? 'contain' : 'cover'
        }
        backgroundColor={loginBgColor}
      >
        <Stack
          display={isProductMadness && (isDesktop || isLandscape) ? 'block' : 'flex'}
          justifyContent={isMobile ? 'flex-start' : 'center'}
          alignItems="center"
          height="100%"
          minHeight="100vh"
        >
          <Routes>
            <Route
              path="/tokenId"
              element={<LoginVersioned {...loginProps} mode={'tokenId' as ELoginMode} />}
            />
            <Route
              path="/username"
              element={<LoginVersioned {...loginProps} mode={'username' as ELoginMode} />}
            />
            <Route
              path="/otp"
              element={<LoginVersioned {...loginProps} mode={'otp' as ELoginMode} />}
            />
            <Route
              path="/*"
              element={<LoginVersioned {...loginProps} mode={'providers' as ELoginMode} />}
            />
          </Routes>
          {supportMultiLanguageEnabled && (
            <Stack pb={2}>
              <LanguagePicker />
            </Stack>
          )}
        </Stack>
      </Background>
      {showCookieOverlay && <CookieConsent setShowCookieOverlay={setShowCookieOverlay} />}
      {showInfoModal && iframeUrl && (
        <InfoModal closeModal={closeInfoModal} iframeUrl={iframeUrl} />
      )}
    </div>
  );
};
