import { BootResponse, OfferData } from '../../constants/apiResponses.types';
import useApi from '../../hooks/useApi';
import { ActionButton, DailyBonus, PostPurchase } from '@appcharge/shared-ui';
import { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import animationData from './confetti-animation.json';
import { EOrderStatus } from '../../pages/checkout/checkout.types';
import useCustomEvents from '../../hooks/useCustomEvents';
import {
  ECollectType,
  EEventsType,
  ELocalStorageKeys,
  EOfferType,
  EPopupSubType,
  EPublisherWebhookEventType,
  EPublisherWebhookOfferEventType,
  EResultOptions,
  EStorePhase,
} from '../../constants/enums';
import './style.scss';
import {
  OrderPopupEvents,
  createGradientOrSolid,
  formatOfferDataToPublisherWebhook,
  getPlatformData,
  localStorageUtil,
} from '../../utils';
import { Gradient } from '@appcharge/shared-ui/lib/components/Helper/types';
import { useTranslation } from 'react-i18next';

export const PopUpsHandler = ({ setProcessing, onPurchaseClick }: any) => {
  const [popupOffers, setPopupOffers] = useState<OfferData[]>([]);
  const [popUpStartHourUTC, setPopUpStartHourUTC] = useState<number>(0);
  const [currentPopupDisplayed, setCurrentPopupDisplayed] = useState<OfferData>();
  const [showConfetti, setShowConfetti] = useState(false);
  const [orderProcessing, setOrderProcessing] = useState<string>();
  const [orderState, setOrderState] = useState<string>();
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [clickedClosePopups, setClickedClosePopups] = useState<string[]>([]);
  const [nextDate, setNextDate] = useState<any>(false);
  const API = useApi({ orderId: orderProcessing });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const dailyBonusRef = useRef<OrderPopupEvents>(null);
  const postPurchaseRef = useRef<OrderPopupEvents>(null);
  const { t } = useTranslation();

  // refetch order status when orderProcessing changes
  useEffect(() => {
    if (orderProcessing) {
      API.getOrder.refetch().catch((err) => console.error(err));
    }
  }, [orderProcessing]);

  // check if order status changed
  useEffect(() => {
    if (orderProcessing && API.getOrder.data) {
      setOrderState(API.getOrder.data?.state);
    }
  }, [API.getOrder?.data?.state !== orderState]);

  useEffect(() => {
    if (
      orderProcessing === undefined ||
      (currentPopupDisplayed?.subType === EPopupSubType.DAILY_BONUS && !dailyBonusRef.current) ||
      (currentPopupDisplayed?.subType === EPopupSubType.POST_PURCHASE && !postPurchaseRef.current)
    )
      return;
    if (
      orderState === EOrderStatus.CHARGE_SUCCEED ||
      orderState === EOrderStatus.CHARGE_FAILED ||
      orderState === EOrderStatus.PAYMENT_FAILED
    ) {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_id: currentPopupDisplayed?.offerId,
          offer_name: currentPopupDisplayed?.offerName,
          result:
            orderState === EOrderStatus.CHARGE_SUCCEED
              ? EResultOptions.SUCCESS
              : EResultOptions.FAILED,
          platform: getPlatformData(),
          type: ECollectType.POPUP,
          reason: orderState !== EOrderStatus.CHARGE_SUCCEED ? orderState : '',
        },
        EStorePhase.POST_LOGIN
      );
    }
    if (orderState === EOrderStatus.CHARGE_SUCCEED) {
      setProcessing(false);
      setShowConfetti(true);
      if (currentPopupDisplayed?.subType === EPopupSubType.DAILY_BONUS)
        dailyBonusRef?.current?.onOrderSuccess();
      else postPurchaseRef?.current?.onOrderSuccess();
      API.getOffers.refetch();
    } else if (
      orderState === EOrderStatus.CHARGE_FAILED ||
      orderState === EOrderStatus.PAYMENT_FAILED
    ) {
      setProcessing(false);
      setShowFailPopup(true);
    } else {
      const timer = setTimeout(() => {
        API.getOrder.refetch().catch((err) => console.error(err));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [orderState, API.getOrder.isRefetching]);

  useEffect(() => {
    const popupArray = API.getOffers?.data?.offers?.filter(
      (offer: OfferData) => offer.offerType === EOfferType.POP_UP
    );

    if (currentPopupDisplayed === undefined && popupArray?.length > 0) {
      setCurrentPopupDisplayed(popupArray[0]);
    }

    setPopupOffers(popupArray);
    setPopUpStartHourUTC(API.getOffers?.data?.publisherSettings?.popUpStartHourUTC);
  }, [API.getOffers?.data?.offers, API.getOffers.isRefetching]);

  useEffect(() => {
    if (popUpStartHourUTC === undefined) return;
    if (currentPopupDisplayed) {
      API.sendPublisherWebhookEvent.mutate({
        eventType: EPublisherWebhookEventType.OFFER,
        data: {
          eventName: EPublisherWebhookOfferEventType.OFFERS_SHOWN,
          countryCode: localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA).playerCountry,
          offers: formatOfferDataToPublisherWebhook([currentPopupDisplayed]),
        },
      });
    }
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setUTCHours(popUpStartHourUTC, 0, 0);
    // Check if the hour is before the current hour, if so, move to the next day
    if (nextDate.getTime() < currentDate.getTime()) {
      nextDate.setUTCDate(nextDate.getUTCDate() + 1);
    }
    setNextDate(nextDate);
  }, [currentPopupDisplayed]);

  const onCollect = async (item: OfferData): Promise<void> => {
    setProcessing(undefined);
    setOrderState(undefined);
    API.getOffers.refetch();
    const freeOrderData: any = {
      data: {
        clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
        paymentProvider: 'free',
        paymentMethod: 'free',
        utmSource: null,
        offerId: item.offerId,
      },
    };
    customEvents.sendCustomEvent(
      EEventsType.COLLECT_CLICKED,
      {
        offer_Id: item.offerId,
        offer_name: item.offerName,
        status: orderState,
        platform: getPlatformData(),
        type: ECollectType.POPUP,
        sub_type: item.subType,
      },
      EStorePhase.POST_LOGIN
    );
    customEvents.sendCustomEvent(EEventsType.POP_UP_CLOSED, {
      offer_id: item.offerId,
      sub_type: item.subType,
      reason: 'collect',
    });
    try {
      setProcessing(true);
      API.createOrder.mutate(freeOrderData, {
        onSuccess: (orderData) => {
          setOrderProcessing(orderData.data.orderId);
        },
        onError: (error) => {
          console.error(error);
          setProcessing(false);
          setShowFailPopup(true);
          dailyBonusRef.current?.onOrderFail();
        },
      });
    } catch (error) {
      console.error('creating an order failed', error);
      dailyBonusRef.current?.onOrderFail();
    }
  };

  const assignNextPopUp = () => {
    if (!popupOffers || popupOffers.length <= 0) {
      setCurrentPopupDisplayed(undefined);
      return;
    }

    const eligibleOffer = popupOffers.find(
      (offer) =>
        !clickedClosePopups.includes(offer.offerId) &&
        currentPopupDisplayed?.offerId !== offer.offerId
    );

    if (eligibleOffer) {
      setCurrentPopupDisplayed(eligibleOffer);
    }
  };

  const onClose = (
    isShowingNextProduct: boolean,
    offerId: string,
    currentProdSeqIndex = 1,
    subType: string
  ) => {
    setClickedClosePopups((prevState) => [...prevState, offerId]);
    setTimeout(assignNextPopUp, 300);

    // don't send the impression if the close is after "collect"
    if (!isShowingNextProduct) {
      API.sendOrderImpression({
        offerId,
        productSequenceIndex: currentProdSeqIndex,
      });
      customEvents.sendCustomEvent(EEventsType.POP_UP_CLOSED, {
        offer_id: offerId,
        sub_type: subType,
        reason: 'close',
      });
    }
  };

  const onTryAgain = () => {
    setShowFailPopup(false);
    window.location.reload();
  };

  const onAnimationSuccessEndNoPreview = () => {
    if (currentPopupDisplayed) {
      setClickedClosePopups((prevState) => [...prevState, currentPopupDisplayed?.offerId]);
    }
    assignNextPopUp();
  };

  return (
    <>
      {currentPopupDisplayed && currentPopupDisplayed.subType === EPopupSubType.DAILY_BONUS && (
        <DailyBonus
          key={currentPopupDisplayed.offerId}
          ref={dailyBonusRef}
          title={currentPopupDisplayed.offerUi.specialOffer?.title || ''}
          titleSize={currentPopupDisplayed.offerUi.specialOffer?.fontSize + 'px'}
          titleWeight={currentPopupDisplayed.offerUi.specialOffer?.fontWeight}
          titleColor={
            currentPopupDisplayed.offerUi.specialOffer?.fontColor
              ? createGradientOrSolid(
                  currentPopupDisplayed.offerUi.specialOffer?.fontColor as Gradient
                )
              : 'white'
          }
          borderWidth={currentPopupDisplayed.offerUi.borderWidth}
          borderColor={currentPopupDisplayed.offerUi.borderColor}
          products={currentPopupDisplayed.productsSequence[0].products}
          onCollect={() => onCollect(currentPopupDisplayed)}
          onClose={({ isShowingNextProduct }) =>
            onClose(
              isShowingNextProduct,
              currentPopupDisplayed.offerId,
              currentPopupDisplayed.productsSequence[0].index,
              currentPopupDisplayed.subType || ''
            )
          }
          backgroundImage={currentPopupDisplayed.offerUi.backgroundImage}
          backgroundColor={currentPopupDisplayed.offerUi?.specialOffer?.backgroundColor?.colorOne}
          buttonText={t('collect')}
          nextProducts={currentPopupDisplayed.productsSequence[1]?.products}
          nextAvailableCollectUTC={nextDate}
          showEvery={currentPopupDisplayed?.showEvery}
          onAnimationSuccessEndNoPreview={onAnimationSuccessEndNoPreview}
          showCloseButton={publisherMetaData.featureFlags?.store_present_daily_bonus_x_icon}
        />
      )}
      {currentPopupDisplayed && currentPopupDisplayed.subType === EPopupSubType.POST_PURCHASE && (
        <PostPurchase
          ref={postPurchaseRef}
          key={currentPopupDisplayed.offerId}
          title={currentPopupDisplayed.offerUi.specialOffer?.title || ''}
          titleColor={
            currentPopupDisplayed.offerUi.specialOffer?.fontColor
              ? createGradientOrSolid(
                  currentPopupDisplayed.offerUi.specialOffer?.fontColor as Gradient
                )
              : 'white'
          }
          titleWeight={currentPopupDisplayed.offerUi.specialOffer?.fontWeight}
          titleSize={currentPopupDisplayed.offerUi.specialOffer?.fontSize + 'px'}
          backgroundColor={currentPopupDisplayed.offerUi?.specialOffer?.backgroundColor?.colorOne}
          backgroundImage={currentPopupDisplayed.offerUi.backgroundImage}
          borderColor={currentPopupDisplayed.offerUi.borderColor}
          borderWidth={currentPopupDisplayed.offerUi.borderWidth}
          buttonText={t('collect')}
          products={currentPopupDisplayed.productsSequence[0].products}
          onActionBtnClicked={() =>
            currentPopupDisplayed.productsSequence[0].price.price === 0
              ? onCollect(currentPopupDisplayed)
              : onPurchaseClick(currentPopupDisplayed)
          }
          showCloseButton={true}
          onClose={({ isShowingNextProduct }: any) =>
            onClose(
              isShowingNextProduct,
              currentPopupDisplayed.offerId,
              currentPopupDisplayed.productsSequence[0].index,
              currentPopupDisplayed.subType || ''
            )
          }
          price={currentPopupDisplayed.productsSequence[0].price}
        />
      )}
      {showConfetti && (
        <Lottie
          animationData={animationData}
          loop={false}
          style={{
            position: 'absolute',
            zIndex: 100000,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          onComplete={() => {
            setShowConfetti(false);
          }}
        ></Lottie>
      )}
      {showFailPopup && (
        <div className={'popup-handler__fail-screen'}>
          <h2>{t('failed.somethingWentWrong')}</h2>
          <ActionButton
            className={'popup-handler__fail-screen__action-button'}
            borderRadius={'7px'}
            text={'Try Again'}
            action={onTryAgain}
          ></ActionButton>
        </div>
      )}
    </>
  );
};
