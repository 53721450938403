import { useEffect } from 'react';
import useCustomEvents from './useCustomEvents';
import {
  EEventsType,
  ELocalStorageKeys,
  EPublisherWebhookEventType,
  EPublisherWebhookOfferEventType,
  EStorePhase,
} from '../constants/enums';
import { OfferData } from '../constants/apiResponses.types';
import useApi from './useApi';
import { OfferEventData } from '../constants/apiRequest.types';
import { ProductData } from '@appcharge/shared-ui/lib/components/Bundles/types';
import { formatOfferDataToPublisherWebhook, localStorageUtil } from '../utils';

const observedItems = [] as string[];

export default function useObserver(
  element: HTMLElement | null,
  data: OfferData,
  offerIndex: number
) {
  const customEvents = useCustomEvents();
  const API = useApi({});

  useEffect(() => {
    if (!element) return;
    const options = {
      threshold: 1.0,
    };
    const observer = new IntersectionObserver((entry, observer) => {
      if (!entry[0].isIntersecting) return;
      if (!observedItems.includes(data.offerId)) {
        observedItems.push(data.offerId);
        customEvents.sendCustomEvent(
          EEventsType.OFFERS_SHOWN,
          {
            offer_id: data.offerId,
            external_id: data.publisherOfferId,
            name: data.offerName,
            sale_percentage: data.dynamicOfferUi?.salePercentage,
            type: data.offerType,
            sub_type: data?.subType,
            offerIndex,
            products: data.productsSequence[data.indexToCollect || 0].products.map(
              (product: ProductData) => ({
                product_id: product.publisherProductId,
                product_name: product.name,
                product_type: product.productType,
                product_quantity: product.quantity,
              })
            ),
            priceInUsdCents: data.productsSequence[data.indexToCollect || 0].priceInUsdCents,
          },
          EStorePhase.POST_LOGIN
        );
        API.sendPublisherWebhookEvent.mutate({
          eventType: EPublisherWebhookEventType.OFFER,
          data: {
            eventName: EPublisherWebhookOfferEventType.OFFERS_SHOWN,
            countryCode: localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA).playerCountry,
            offers: formatOfferDataToPublisherWebhook([data]),
          } as OfferEventData,
        });
      }
      observer.disconnect();
    }, options);
    observer.observe(element);
  }, [element]);
}
