export enum ELocalStorageKeys {
  OVERRIDE_STORE = 'overrideStore',
  SESSION_TOKEN = 'sessionToken',
  ANALYTICS = 'analytics',
  PLAYER_DATA = 'playerData',
  OFFERS_COOLDOWN = 'offersCooldown',
  PUBLISHER_META = 'publisherMeta',
  ORDER_ID = 'oid',
  OFFER_API_OVERRIDE = 'offersApiOverride',
  BOOT_API_OVERRIDE = 'bootApiOverride',
  POST_LOGIN_API_OVERRIDE = 'postLoginApiOverride',
  OTP_API_OVERRIDE = 'otpApiOverride',
  IS_SAVE_TO_HOME_PAGE_MODAL_OPEN = 'isSaveToHomePageModalOpen',
  STORE_ADD_TO_HOME_SCREEN_NOTIFICATION = 'store_add_to_home_screen_notification',
  CURRENT_AVAILABILITY = 'currentAvailability',
  IS_FREE_ORDER_SELECTED = 'isFreeOrderSelected',
  DAILY_BONUS_BADGES = 'dailyBonusBadges',
  TIME_LEFT = 'timeLeft',
  LAST_SAVED_TIME = 'lastSavedTime',
  DEFAULT_LANGUAGE = 'defaultLanguage',
}

export enum ESessionStorageKeys {
  SESSION_DATA = 'sessionData',
  REDIRECT_STATE = 'redirectState',
}

export enum EStorageType {
  sessionStorage = 'sessionStorage',
  localStorage = 'localStorage',
}

export enum ESupportModel {
  APPCHARGE = 'AppCharge',
  PLAYER = 'Player',
  PUBLISHER = 'Publisher',
}

export enum EBundlesViewModel {
  LINEAR = 'linear',
  TWO_IN_ONE = 'twoInOne',
  THREE_IN_ONE = 'threeInOne',
}

export enum ESectionsViewModel {
  DEFAULT = 'default',
  BAGEL_CODE = 'bagelcode',
  PIPA = 'pipa',
}

export enum EBundlesInternalViewModel {
  AK = 'ak',
  MATCH = 'match',
  COMMUNIX = 'communix',
  MAIN_SUB = 'mainSub',
  MAIN_SUB_ALT = 'mainSubAlt',
  ZERO = 'zero',
  BAGELCODE = 'bagelcode',
  LOCO_BINGO = 'locobingo',
  JACKPOT_SLOTS = 'jackpot-slots',
  BIG_FISH_CASINO = 'big-fish-casino',
  CASHMAN_CASINO = 'cashman-casino',
  LIGHTNING_LINK_CASINO = 'lightning-link-casino',
  HEART_OF_VEGAS = 'heart-of-vegas',
  ME2ON = 'me2on',
  PLAY_SIMPLE = 'play-simple',
  PIPA = 'pipa',
}

export enum ESpecialOfferInternalViewModel {
  HUGE = 'hug',
  MATCH_MASTERS = 'match-masters',
  POKER_FACE = 'pokerFace',
  BAGELCODE = 'bagelcode',
  LOCO_BINGO = 'locobingo',
  ME2ON = 'me2on',
  PIPA = 'pipa',
  JACKPOT_SLOTS = 'jackpot-slots',
  BIG_FISH_CASINO = 'big-fish-casino',
  PLAY_SIMPLE = 'play-simple',
}

export enum ESupportType {
  POST_LOGIN = 'postLogin',
  PRE_LOGIN = 'preLogin',
}

export enum EStorePhase {
  PRE_LOGIN = 'PreLogin',
  POST_LOGIN = 'PostLogin',
  POST_ORDER = 'PostOrder',
  OTHER = 'Other',
}

export enum EPublisherWebhookEventType {
  LOGIN = 'login',
  OFFER = 'offer',
}

export enum EPublisherWebhookOfferEventType {
  OFFERS_SHOWN = 'offers_shown',
  OFFERS_LOADED = 'offers_loaded',
}

export enum EEventsType {
  LOGIN_LAND = 'LOGIN_LAND',
  LOGIN_CLICKED = 'LOGIN_CLICKED',
  LOGIN_RESULT = 'LOGIN_RESULT',
  LOGIN_OTP_IOS_PRESENTED = 'LOGIN_OTP_IOS_PRESENTED',
  LOGIN_CANCELED = 'LOGIN_CANCELED',
  WEBVIEW_LAND = 'WEBVIEW_LAND',
  SHOP_LAND = 'SHOP_LAND',
  PROFILE_MENU_CLICKED = 'PROFILE_MENU_CLICKED',
  LOAD_MORE_CLICKED = 'LOAD_MORE_CLICKED',
  LOGOUT = 'LOGOUT',
  ERROR = 'ERROR',
  BUNDLE_CLICKED = 'BUNDLE_CLICKED',
  OFFER_CLICKED = 'OFFER_CLICKED',
  PAYMENT_CHOOSE = 'PAYMENT_CHOOSE',
  ORDER_RESOLVED = 'ORDER_RESOLVED',
  PAYMENT_CANCEL = 'PAYMENT_CANCEL',
  SUPPORT_FORM_OPEN = 'SUPPORT_FORM_OPEN',
  SUPPORT_FORM_SUBMIT = 'SUPPORT_FORM_SUBMIT',
  SUPPORT_FORM_CANCEL = 'SUPPORT_FORM_CANCEL',
  ERROR_POPUP_PRESENTED = 'ERROR_POPUP_PRESENTED',
  OFFERS_LOADED = 'OFFERS_LOADED',
  OFFERS_SHOWN = 'OFFERS_SHOWN',
  OFFERS_IMAGES_LOADED = 'OFFERS_IMAGES_LOADED',
  OFFERS_IMAGE_FAILED_LOADING = 'OFFERS_IMAGE_FAILED_LOADING',
  POP_UP_CLOSED = 'POP_UP_CLOSED',
  COLLECT_CLICKED = 'COLLECT_CLICKED',
  COLLECT_RESOLVED = 'COLLECT_RESOLVED',
  COMPLETE_SCREEN_PRESENTED = 'COMPLETE_SCREEN_PRESENTED',
  COMPLETE_SCREEN_BACK_TO_GAME = 'COMPLETE_SCREEN_BACK_TO_GAME',
  COMPLETE_SCREEN_BACK_TO_SHOP = 'COMPLETE_SCREEN_BACK_TO_SHOP',
  HOME_SCREEN_BUTTON_CLICK = 'HOME_SCREEN_BUTTON_CLICK',
}

export enum ECollectType {
  POPUP = 'popup',
  OFFER = 'offer',
}

export enum ELogoSize {
  SMALL = 'small',
  LARGE = 'big',
}

export enum EPlatformType {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export enum EQueryParams {
  MESSAGE = 'msg',
  ORDER_ID = 'order_id',
  ERROR = 'error',
  UTM_SOURCE = 'utm_source',
  LOCAL_ADDRESS = 'local_address',
  LOGIN_REDIRECT = 'login_redirect',
  IS_IFRAME = 'is_iframe',
  CURRENCY_CODE = 'currencyCode',
}

export enum EQueryKeys {
  ORDER = 'order',
  BUNDLES = 'bundles',
  BUNDLE = 'bundle',
  PAYPAL = 'paypal',
  BOOT = 'boot',
  POST_LOGIN = 'post-login',
  OFFERS = 'offers',
  OTP = 'otp',
  FEATURE_FLAG = 'feature-flag',
  PRODUCTS = 'products',
  LANGUAGES = 'languages',
  TRANSLATIONS = 'translations',
}

export enum EOfferType {
  BUNDLE = 'bundle',
  SPECIAL_OFFER = 'special_offer',
  POP_UP = 'PopUp',
}

export enum EerrorCodes {
  PAYMENT = 'payment',
  AUTH = 'auth',
}

export enum EDeviceType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
}

export enum EOTPDesktopAction {
  REDIRECT = 'redirect',
  QR = 'qr',
}

export enum EResultOptions {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum ELoginResultReason {
  OK = 'OK',
  UNKNOWN = 'unknown',
}

export enum EBalancesTheme {
  DEFAULT = 'default',
  BAGELCODE = 'bagelcode',
  SPACEGO = 'spacego',
  PLAY_SIMPLE = 'play-simple',
  PIPA = 'pipa',
}

export enum EFeatureFlag {
  STORE_INDEPENDENT_CHECKOUT_FLOW = 'store_independent_checkout_flow',
  STORE_CHECKOUT_NEW = 'store_checkout_new',
  STORE_ADD_TO_HOME_SCREEN_NOTIFICATION = 'store_add_to_home_screen_notification',
  STORE_MULTI_LANGUAGE_SUPPORT = 'store_multi_language_support',
  STORE_SHOW_DAILY_BONUS_X_ICON = 'store_present_daily_bonus_x_icon',
  STORE_SEND_OS_AUTH_LOGIN = 'store_send_os_authlogin',
  STORE_DAILY_BONUS_BADGE = 'store_daily_bonus_badge',
  STORE_SHOW_VERSION = 'store_show_version',
  STORE_LOGINV2 = 'store_loginv2',
  STORE_WEBVIEW_SHOP_OTP = 'store_webview_show_otp',
  STORE_REFRESH_OFFERS_STALE_MODE = 'store_refresh_offers_stale_mode',
}

export enum ECheckoutPageEvent {
  BACK_TO_STORE = 'backToStore',
  BACK_TO_GAME = 'backToGame',
  SUPPORT = 'support',
  ORDER_CREATED = 'appcharge_order_created',
  PAYMENT_INTENT_SUCCESS = 'appcharge_payment_intent_success',
  PAYMENT_INTENT_FAILED = 'appcharge_payment_intent_failed',
  ORDER_COMPLETED_SUCCESS = 'appcharge_order_completed_success',
  ORDER_COMPLETED_FAILED = 'appcharge_order_completed_failed',
  CLOSE_CHECKOUT = 'appcharge_close_checkout',
  CHECKOUT_OPENED = 'appcharge_checkout_opened',
}

export enum ESalePercentageDisplayType {
  PERCENTAGE = 'percentage',
  MULTIPLIER = 'multiplier',
  FIXED_AMOUNT = 'fixed_amount',
}

export enum EPopupSubType {
  DAILY_BONUS = 'DailyBonus',
  POST_PURCHASE = 'PostPurchase',
}

export enum ELoginMode {
  PROVIDERS = 'providers',
  USERNAME = 'username',
  TOKEN_ID = 'tokenId',
  OTP = 'otp',
}

export enum ELoginMethods {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
  USERNAME = 'userPassword',
  TOKEN = 'userToken',
  OTP = 'otp',
}

export enum ELoginEvents {
  LOGIN_CANCELED = 'LOGIN_CANCELED',
  LOGIN_SCREEN_PRESENTED = 'LOGIN_SCREEN_PRESENTED',
  LOGIN_APPROVAL = 'LOGIN_APPROVAL',
  LOGIN_BUTTON_CLICKED = 'LOGIN_BUTTON_CLICKED',
  LOGIN_OTP_IOS_BUTTON = 'LOGIN_OTP_IOS_BUTTON',
  LOGIN_OTP_ANDROID_BUTTON = 'LOGIN_OTP_ANDROID_BUTTON',
  OTP_BUTTON_CLICKED = 'OTP_BUTTON_CLICKED',
  OTP_CODE_ENTERED = 'OTP_CODE_ENTERED',
  PLAYER_ID_BUTTON_CLICKED = 'PLAYER_ID_BUTTON_CLICKED',
  PLAYER_PASSWORD_BUTTON_CLICKED = 'PLAYER_PASSWORD_BUTTON_CLICKED',
}

export enum EOTPModel {
  STATIC = 'static',
  DYNAMIC_LINK = 'dynamicLink',
}

export enum EOTPTextModel {
  SIX_DIGITS = 'sixDigits',
  FOUR_DIGITS = 'fourDigits',
  MOBILE_ONLY = 'mobileOnly',
}

export enum EAppleResponseType {
  CODE = 'code',
  ID_TOKEN = 'code id_token',
}
