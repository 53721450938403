import { Box, Button, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import confetti from '../../assets/animations/confetti.json';
import newCheckoutConfetti from '../../assets/animations/new-checkout-confetti.json';
import { useEffect, useState } from 'react';
import {
  AcCircularLoader,
  ActionButton,
  Background,
  Checkout,
  MessagePage,
} from '@appcharge/shared-ui';
import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import OrderSet from '../../components/order-set/order-set';
import useScript from '../../hooks/useScript';
import { EOrderStatus } from './checkout.types';
import useCustomEvents from '../../hooks/useCustomEvents';
import {
  ECheckoutPageEvent,
  EEventsType,
  EQueryParams,
  EResultOptions,
  EStorePhase,
  ESupportType,
} from '../../constants/enums';
import BackToGameButton from '../../components/BackToGameButton/BackToGameButton';
import { getReturnToGameLinkAddress } from '../../components/BackToGameButton/BackToGameButton.utils';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/Spinner/Spinner';
import { getPlatformData, sendMessageToIframeParent } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
import { StyledTextContainer } from './style';
import useUrlQuery from '../../hooks/useUrlQuery';

enum PageStatus {
  COMPLETE = 'completedScreen',
  LOADER = 'loaderScreen',
}

const CheckoutPage = () => {
  const [useNewCheckout, setUseNewCheckout] = useState<boolean>(false);

  const { orderId } = useParams();
  const currencyCode = useUrlQuery(EQueryParams.CURRENCY_CODE);
  const { t } = useTranslation();
  const API = useApi({ orderId: orderId });
  const orders = API.getOrder;
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const scripts = useScript();
  const theme = publisherMetaData?.storeTheme as any;
  const [returnToGameLinkAddress, setReturnToGameLinkAddress] = useState<string>();
  const navigate = useNavigate();
  const [animationDone, setAnimationDone] = useState(false);
  const [page, setPage] = useState<PageStatus>(PageStatus.LOADER);
  const referenceSize = '12px';
  const referenceWeight = '100';

  useEffect(() => {
    const interval = setInterval(() => {
      window.parent.postMessage('iframe-loaded', '*');
    }, 500);

    const handleMessage = (event: { data: string }) => {
      if (event.data === 'stop-messages') {
        clearInterval(interval);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      clearInterval(interval);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    setUseNewCheckout(!!publisherMetaData.featureFlags?.store_independent_checkout_flow);
  }, []);

  if (orders.isIdle) {
    orders.refetch();
  }

  useEffect(() => {
    if (!publisherMetaData.featureFlags?.store_independent_checkout_flow) return;
    if (page === PageStatus.COMPLETE) {
      customEvents.sendCustomEvent(
        EEventsType.COMPLETE_SCREEN_PRESENTED,
        {
          status: EResultOptions.SUCCESS,
          currency_code: currencyCode,
          platform: getPlatformData(),
          order_id: orderId,
        },
        EStorePhase.POST_LOGIN
      );
    }
  }, [page]);

  const orderEventWrapper = (status: string) => {
    customEvents.sendCustomEvent(
      EEventsType.ORDER_RESOLVED,
      {
        status: status,
        order_id: orderId,
        offer_id: orders.data.offer.offerId,
        offer_name: orders.data.offer.offerName,
        offer_price_usd: orders.data.offer.priceInUsd,
        sale_percentage: orders.data.offer.salePercentage,
        platform: getPlatformData(),
      },
      EStorePhase.POST_LOGIN
    );
  };

  if (orders.isSuccess && page === PageStatus.LOADER) {
    switch (orders.data.state) {
      case EOrderStatus.CHARGE_PENDING:
      case EOrderStatus.PAYMENT_PENDING:
        setTimeout(() => {
          if (orders.isRefetching) return;
          orders.refetch();
        }, 3000);
        break;
      case EOrderStatus.CHARGE_FAILED:
        orderEventWrapper(t('checkout.chargeFailed'));
        setTimeout(() => {
          navigate(
            `../failed?error=${EOrderStatus.CHARGE_FAILED}&order_id=${orderId}&msg=${
              orders.data.publisherErrorMessage || ''
            }&currencyCode=${currencyCode}`
          );
        }, 1000);
        break;
      case EOrderStatus.CHARGE_SUCCEED:
        orderEventWrapper(t('checkout.chargedSucceed'));
        setPage(PageStatus.COMPLETE);
        break;
      case EOrderStatus.PAYMENT_FAILED:
        orderEventWrapper(t('checkout.paymentFailed'));
        setTimeout(() => {
          navigate(
            `../failed?error=${EOrderStatus.PAYMENT_FAILED}&order_id=${orderId}&msg=${
              orders.data.publisherErrorMessage || ''
            }&currencyCode=${currencyCode}`
          );
        }, 1000);

        break;
    }
  }

  const backToStore = () => {
    if (useNewCheckout) {
      sendMessageToIframeParent(ECheckoutPageEvent.BACK_TO_STORE, {});
    } else {
      navigate('../shop');
    }
  };

  useEffect(() => {
    const deepLinks = publisherMetaData?.integration.deepLinks;
    if (deepLinks.length > 0) {
      const backToGameLink = getReturnToGameLinkAddress(deepLinks);
      setReturnToGameLinkAddress(backToGameLink);
    }
  }, [setReturnToGameLinkAddress, publisherMetaData]);

  useEffect(() => {
    if (page !== PageStatus.COMPLETE) return;
    scripts.injectScript(publisherMetaData.supportConfiguration?.postLoginSnippet);
  }, [page]);

  const isBackToGameBtnShown =
    publisherMetaData?.integration.backToGameButtonOn &&
    publisherMetaData.storeTheme.completedScreen.backToGameButtonText &&
    returnToGameLinkAddress;

  const contactSupportClicked = () => {
    customEvents.sendCustomEvent(
      EEventsType.SUPPORT_FORM_OPEN,
      {
        phase: EStorePhase.POST_ORDER,
        order_id: orderId,
      },
      EStorePhase.POST_LOGIN
    );
    if (publisherMetaData.supportConfiguration?.postLoginSnippet) {
      API.sendEmptySupport(
        publisherMetaData.storeTheme.publisherId,
        ESupportType.POST_LOGIN,
        EStorePhase.POST_ORDER,
        orderId
      );
    } else {
      orders.remove();
      navigate(`/support/${ESupportType.POST_LOGIN}/${EStorePhase.POST_LOGIN}`);
    }
  };
  return (
    <>
      {useNewCheckout ? (
        <div className={'checkout-completion'}>
          {page === PageStatus.LOADER && <AcCircularLoader text={t('processing')} />}
          {page === PageStatus.COMPLETE && (
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <div className={'checkout-completion__close-icon'}>
                <IconButton onClick={backToStore} size="small">
                  <CloseIcon style={{ fontSize: 40, color: 'white' }} />
                </IconButton>
              </div>
              {!animationDone && (
                <Lottie
                  className="confetti-animation"
                  animationData={newCheckoutConfetti}
                  loop={false}
                  onComplete={() => setAnimationDone(true)}
                  style={{
                    position: 'absolute',
                  }}
                />
              )}
              <StyledTextContainer
                headerColor={theme[PageStatus.COMPLETE].headerColor}
                headerSize={theme[PageStatus.COMPLETE].headerSize}
                headerWeight={theme[PageStatus.COMPLETE].headerWeight}
                textColor={theme[PageStatus.COMPLETE].textColor}
                textSize={theme[PageStatus.COMPLETE].textSize}
                textWeight={theme[PageStatus.COMPLETE].textWeight}
                className={'checkout-completion__text-container'}
              >
                <h1
                  className={'checkout-completion__text-container__header'}
                  data-testid="storeResolveHeader"
                >
                  {theme[PageStatus.COMPLETE].headerText}
                </h1>
                <h2
                  className={'checkout-completion__text-container__sub-header'}
                  data-testid="storeResolveDesc"
                >
                  {[theme[PageStatus.COMPLETE].text]}
                </h2>
              </StyledTextContainer>
              <OrderSet products={orders.data.offer.products} showBackground={false} />
              {isBackToGameBtnShown && (
                <BackToGameButton
                  useNewCheckout={useNewCheckout}
                  btnText={publisherMetaData.storeTheme.completedScreen.backToGameButtonText}
                  btnColor={publisherMetaData.storeTheme.general.buttonColor}
                  width={'100%'}
                  borderRadius={'4px'}
                  returnToGameLinkAddress={returnToGameLinkAddress}
                />
              )}
              <Box mt={2}>
                <Button
                  variant="text"
                  id="support"
                  fullWidth
                  sx={{
                    color: 'white',
                    fontSize: '12px',
                    padding: '20px 0',
                  }}
                  onClick={contactSupportClicked}
                >
                  {t('contactSupport')}
                </Button>
              </Box>
              <div
                style={{
                  color: publisherMetaData.storeTheme.completedScreen.textColor,
                  fontSize: referenceSize,
                  fontWeight: 400,
                }}
                className="fadein"
              >
                <span>{t('paymentReferenceID')}</span>
                <br />
                <span>{orderId}</span>
              </div>
              {/*</Checkout>*/}
            </Box>
          )}
        </div>
      ) : (
        <Background
          backgroundImageDesktop={publisherMetaData.storeTheme.general.backgroundImageDesktop}
          backgroundImageMobile={publisherMetaData.storeTheme.general.backgroundImageMobile}
          minHeight={'100vh'}
          height="100%"
        >
          {page === PageStatus.LOADER && <Spinner hideProcessingText />}
          {page === PageStatus.COMPLETE && (
            <MessagePage
              headerText={theme[PageStatus.COMPLETE].headerText}
              headerColor={theme[PageStatus.COMPLETE].headerColor}
              headerSize={theme[PageStatus.COMPLETE].headerSize}
              text={[theme[PageStatus.COMPLETE].text]}
              textColor={theme[PageStatus.COMPLETE].textColor}
              textSize={theme[PageStatus.COMPLETE].textSize}
              fontFamily={publisherMetaData.storeTheme.general.font as any}
              preview={false}
              headerWeight={publisherMetaData.storeTheme.paymentScreen.textWeight}
              textWeight={theme[PageStatus.COMPLETE].textWeight}
            >
              <Checkout>
                {!animationDone && (
                  <Lottie
                    className="coinsAnimation"
                    animationData={confetti}
                    loop={false}
                    onComplete={() => setAnimationDone(true)}
                    style={{
                      zIndex: '-1',
                      transform: 'scale(0.5)',
                      top: '-50vh',
                    }}
                  />
                )}
                <OrderSet products={orders.data.offer.products} />
                {isBackToGameBtnShown && (
                  <BackToGameButton
                    useNewCheckout={useNewCheckout}
                    btnText={publisherMetaData.storeTheme.completedScreen.backToGameButtonText}
                    btnColor={publisherMetaData.storeTheme.general.buttonColor}
                    returnToGameLinkAddress={returnToGameLinkAddress}
                  />
                )}
                {publisherMetaData?.integration.backToStoreButtonOn && (
                  <ActionButton
                    text={publisherMetaData.storeTheme.completedScreen.backToStoreText}
                    action={backToStore}
                    colors={publisherMetaData.storeTheme.general.buttonColor}
                    borderRadius={'4px'}
                  />
                )}
                <div
                  style={{
                    color: publisherMetaData.storeTheme.completedScreen.textColor,
                    fontSize: referenceSize,
                    fontWeight: referenceWeight,
                  }}
                  className="fadein"
                >
                  <span>{t('paymentReferenceID')}</span>
                  <br />
                  <span>{orderId}</span>
                </div>
                <Box mt={2}>
                  <Button
                    variant="text"
                    id="support"
                    fullWidth
                    sx={{
                      color: 'white',
                      fontSize: '12px',
                    }}
                    onClick={contactSupportClicked}
                  >
                    {t('contactSupport')}
                  </Button>
                </Box>
              </Checkout>
            </MessagePage>
          )}
        </Background>
      )}
    </>
  );
};
export default CheckoutPage;
